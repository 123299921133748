.loginButton {
    color: rgba(255,255,255,0.707) !important;
    font-size: 1.5rem !important;
}

.loginButton:hover {
    background-color: white !important;
    color: black !important;
    font-weight: 600 !important;
    border: 1px solid black !important;
}
