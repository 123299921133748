p {
    letter-spacing: 0;
}

th,
tr, 
.tr,
.th {
    font-size: 14px;
}


.hidden {
  display: none !important;
}

.facilitiesWrapper {
  overflow: scroll;
}

.pageWrapper {
  padding-top: 3.25rem;
}

.MuiButton-startIcon.MuiButton-iconSizeSmall {
  margin-bottom: 3px;
}