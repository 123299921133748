@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import 'node_modules/bulma/bulma.sass';
@import 'node_modules/react-toastify/scss/main.scss';
@import './variables.scss';
@import './landing.scss';
@import './App.scss';
@import './grid.scss';
@import './form.scss';

html {
  overflow: hidden;
}
#root {
  height: 100vh;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
  // max-height: 100vh; the padding in the body makes it so this always shows the overflow scroller in the main menu grids WP-220
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(132, 153, 153, 0.75);
  border-radius: 5px;
  max-height: 10px !important;
}