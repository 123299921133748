.MuiInputBase-root.Mui-disabled {
    color: inherit !important;
}

.createRecordForm {
    color: red !important;
    .MuiPaper-root {
        box-shadow: none !important;
    }
}

.cardSpinner {
    padding: 5em 0em;
}

.MuiTabs-flexContainer {
    .MuiButtonBase-root {
        padding: 12px 16px;
        min-height: 48px;
    }
}

.Mui-disabled {
    color: currentColor !important;
    -webkit-text-fill-color: currentColor !important;
    &::before {
        border: none !important;
    }
    >.MuiInputAdornment-root,.MuiAutocomplete-endAdornment {
        display: none;
    }
}

.MuiTableCell-root {
    input[type="date"]::-webkit-calendar-picker-indicator {
       display: none;
    }

}

.MuiFormLabel-root.Mui-error, .MuiFormHelperText-root.Mui-error {
    color: #d32f2f !important;
}
.MuiInput-underline.Mui-error:after {
    border-bottom-color: #d32f2f !important;
}

// disable the buttons on the default array renderer for mui
// .MuiToolbar-root {
//     .MuiIconButton-root {
//         display: none;
//     }
// }

// .MuiAccordionSummary-root {
//     .MuiIconButton-root {
//         display: none;
//     }
// }

.materialTableToolbar {
    .MuiToolbar-root {
        .MuiIconButton-root {
            display: inline-block;
        }
    }
    
    .MuiAccordionSummary-root {
        .MuiIconButton-root {
            display: inline-block;
        }
    }
}

.MuiTablePagination-actions {
    .MuiIconButton-root {
        display: inline-flex;
    }
}

.formWrapper {
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true {
        max-width: 100% !important;
    }
    .MuiTablePagination-root {
        padding-right: 1.5rem;
    }
    .materialTableWrapper {
        & {
            max-width: 100% !important;
        }
        >.MuiTablePagination-spacer {
            display: none;
        }
    }
}

.overwrite-max-width {
    max-width: 90% !important;
}