.table {
    width: 100%;
    overflow: scroll;
    padding-bottom: 10rem;
}

.gridTop {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 4px;
    align-items: center;
    justify-content: center;
    padding-left: 1%;
}
.showing {
    display: flex;
    text-align: center;
    font-size: 13px;
    justify-content: center;
    min-width: 500px;

    div:first-child {
        margin-left: 1%;
    }

    .selectSection {
        min-width: 190px;
        margin-left: 0.5%;

        position: relative;
        bottom: 15px;
        .loadingSpinnerWrapper {
            padding: 50%;
            max-height: 80%;
            color: $dark-blue;
        }
    }
}
.hp {
    line-height: 1;
    font-style: italic !important;
    font-size: 0.8em !important;
}
.controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: medium;
    color: #38518af8;
    z-index: 3;
}

.is-size-65 {
    display: flex;
    width: 20px;
    &:hover {
        cursor: pointer;
        color: #2536758e !important;
    }
}

.gridLoading {
    position: absolute;
    top: 36%;
    left: 15px;
    font-size: 1.15rem;
    color: $dark-blue;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3b4465;
    margin: 0rem 0.25rem 0.25rem 0.25rem !important;
    font-size: smaller;
    min-width: 420px;
    align-self: flex-start;
    justify-self: center;
    .pagDiv {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .outer {
        position: relative;
        top: 8px;
        input {
            width: 50.5px;
        }
    }
    .arrow {
        background-color: rgba(255, 255, 255, 0);
        border: none;
        font-weight: 700;
        color: $dark-blue;
        font-size: medium;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        padding: 0.9vh 2px 1px 2px;
        &:disabled {
            color: $grey;
        }
    }
}

.columnHeader {
    display: flex;
    align-items: center;
    overflow: hidden;
}
.grid {
    .selectSection {
        &.single {
            .lastAction{
                font-size: .6rem;
                font-style: italic;
                margin: .2rem 0px;
            }
            div:last-child:not(:only-child) {
                margin-bottom: 1rem;
            }
            
        }
    }
    padding-bottom: 10rem;
    &.section {
        padding-top: 0px;
    }

    pre {
        background-color: inherit;
        padding: 0px 0px 0.25rem 0px;
        border: solid 1.5px rgba(202, 202, 202, 0.801);
        width: 100%;
        margin-left: 5%;
        overflow: visible;
        tbody,
        .table {
            position: initial;
        }
        thead {
            tr {
                border-bottom: solid 1px rgba(202, 202, 202, 0.801);
            }
        }
        tr {
            color: #3c5285 !important;
            font-size: 1em !important;
        }
        th {
            color: #3c5285 !important;
            font-size: 1em !important;
        }
    }

    .modal-background {
        z-index: 3;
        background-color: #3b446571;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal-content {
        z-index: 3;
    }
    .modalButton {
        cursor: pointer;
    }

    .modal-card-head,
    .modal-card-foot {
        background-color: $blue-grey;
        padding: 10px;
        border-radius: 1px !important;
        // max-width: fit-content;
    }
    .modal-card-body {
        background-color: $light-blue;
        padding: 10px;
        &.confirm {
            background-color: white;
            width: fit-content;
            overflow: visible;
            button {
                padding: 3%;
            }
        }
    }
    .modal-card {
        position: relative;
        width: 97.1%;
        // min-width: fit-content;
        z-index: 3;
        color: $dark-blue;
        max-height: 100%;
        overflow: visible;
        &.batchAction {
            position: fixed;
            left: 30%;
            right: 30%;
            top: 40%;
            width: 40%;
            display: flex;
            align-items: center;
            border: 1px solid $dark-blue;
            overflow: visible;
            // font-size: small;
            font-size: 14px;
        }
        &.singleAction {
            // right: 100%;
            border: 1px solid $dark-blue;
            overflow: visible;
            // font-size: small;
            text-align: center;
            position: fixed;
            left: 30%;
            right: 30%;
            top: 40%;
            width: 40%;
            display: flex;
            align-items: center;
        }
        &.undoAction {
            // right: 100%;
            position: fixed;
            left: 35%;
            right: 35%;
            top: 40%;
            width: 30%;
            display: flex;
            align-items: center;
            border: 1px solid $dark-blue;
            overflow: visible;
            // font-size: small;
            text-align: center;
        }
        &.deleteNote {
            // right: 100%;
            position: fixed;
            left: 50%;
            right: 50%;
            top: 40%;
            width: fit-content;
            border: 1px solid $dark-blue;
            // font-size: small;
        }
    }

    .modal-card-foot {
        max-height: 50vh;
        padding-bottom: 0px;
        .form {
            margin-top: 0px;
            padding: 0px;

            .radio {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0.25% 1.5% 0.25% 0.25%;
            }
            .control {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
            }
            .input {
                width: 50vw;
                color: $dark-blue;
                &:focus {
                    box-shadow: none;
                    border-color: $grey;
                }
            }
            .react-datepicker__input-container {
                margin-left: 5px;
                max-width: 13vw;
            }
        }
    }
}


.th {
    font-size: 105%;
}

.table.is-hoverable .tbody .tr:not(.is-selected) {
    background-color: white;
}
.table.is-hoverable .tbody .tr:not(.is-selected):hover {
    background-color: rgba(215, 231, 231, 0.1);
}

.rdw-editor-main {
    max-height: 25vh;
}

.searchIcon {
    color: $blue;
    margin-right: 5px;
    &:hover {
        color: $dark-blue;
    }
}
// .table .cell {
//     padding: 0.5em 0.6em;
//     display: flex;
// font-weight: bold;
// font-size: 14px;
// &.header{

//     border-bottom: 2px solid rgba(128, 128, 128, 0.541);
// }
// &.data {
//     font-size: 90%;
//     border-bottom: 1px solid rgba(128, 128, 128, 0.541);
//     background-color: white;
// }
// }

// .cell {
//     overflow: hidden;
// }

// .hover {
//     background-color: rgba(215, 231, 231, 0.1) !important;
// }

.gridWrapper {
    background-color: #d7e7f1;
    padding: 1rem;
    padding-bottom: 3rem;
    display: block;
    position: relative;
    // overflow: auto;
}

.table .td,
.table .th {
    padding: 0.5em 0.6em;
}

//for resizable columns

.tableCell {
    font-size: 95%;
    // font-weight: bold;
    // display: flex;
    // justify-content: center;
    // flex-direction: column;
    // align-items: center;
    // text-align: center;
    // overflow: hidden;
}

// .table.is-hoverable .tbody .tr:not(.is-selected) {
//     background-color: white;
// }
// .table.is-hoverable .tbody .tr:not(.is-selected):hover {
//     background-color: rgba(215, 231, 231, 0.1);
// }

.resizable {
    // padding: 1rem;
    // display: block;

    &.table {
        display: inline-block;
        border-spacing: 0;
        border: 1px solid $blue-grey;
        // line-height: 1.3;
        color: $dark-blue;
        // overflow: auto;
        min-width: none;
        .resizer {
            display: inline-block;
            background: $grey-blue;
            width: 3px;
            padding: 0 2px;
            height: 1rem;
            position: absolute;
            right: 0;
            top: 1.25rem;
            bottom: 1.25rem;
            margin: auto;
            transform: translateX(50%);
            z-index: 1;
            touch-action: none;
            &:hover {
                height: 3.25rem;
                top: 0;
                bottom: 0;
                background: $blue-grey;
            }

            &.isResizing {
                height: 3.25rem;
                background: $dark-blue;
            }
        }

        .tr {
            border-bottom: 1px solid $blue-grey;
            display: flex;
            flex-wrap: nowrap;
        }
        // :last-child {
        //   .tr {
        //     border-bottom: 0;
        //   }
        // }

        .th,
        .td {
            // display: flex;
            // margin: 0;
            // padding: 0.25rem;
            //   border-bottom: 1px solid black;
            //   border-right: 1px solid black;
            // position: relative;
            // vertical-align: middle;
            align-self: center;
            // font-weight: bold;
            // text-align: center;
        }
        .th {
            // min-height: 3.25rem;
            // padding: 0.75% 0%;
            .header {
                display: flex;
                align-items: center;
                // padding-left: .5em;
                overflow: hidden;
            }
            // div:last-child {
            //     width: 100%;
            //     padding-left: 0.5rem;
            // }
            .columnHeader {
                width: inherit;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
        }
    }
}

.breadCrumbContainer {
    display: flex;
    align-content: center;
    margin-left: 15px;
    margin-bottom: 5px
}

.aboveGrid {
    display: flex;
    width: 50%;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 0.75rem;
    // height: fit-content;
}

.controlButtonWrapper {
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    div {
        padding: 0.2rem;
        
        border-radius: 2px;
        &.active {
            background-color: #38518af8;
            color: #d7e7f1;
            &:hover {
                background-color: #d7e7f1;
                color: #38518af8
            }
        }
    }
}

.columnSelectionModalWrapper {
    position: absolute;
    top: 2.5rem;
    right: 3px;
    z-index: 100;
    background-color: white;
    justify-content: flex-start;
    align-items: center;
    border: solid 1px #38518af8;
    border-radius: 2px;
    overflow-y: scroll;
    max-height: 75vh;
    box-shadow: inset 0px 0px 1px $dark-blue;
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */

    .columnSelectionModal {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        &.hidden {
            display: none;
        }
        .columnSelectionRow {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            &.react-draggable-dragging {
                opacity: 0.5;
            }
            .columnSelectionCheckboxWrapper {
                padding-right: 1rem;
                padding-top: 0.15rem;
                display: flex;
            }
            .columnSelectionLabelWrapper {
                display: flex;
            }
        }
    }
}

.layout {
    width: 200px;
}


.resizer {
  display: inline-block;
  width: 5px;
  height: 80%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  background-color: lightslategray;
}